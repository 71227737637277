<template>
  <el-header
    class="header min-width"
    :class="{
      'login-on': !!user.name, // 已登录
      'login-off': !user.name // 未登录
    }"
    height="56px"
  >
    <div class="layout-header">
      <div
        class="header-logo"
        direction="vertical"
        :style="{'height': siteInfo.configuration?.logoHeight ? siteInfo.configuration?.logoHeight + 'px' : '24px'}"
      >
        <div class="logo__img">
          <a :href="homehref">
            <span
              class="logo__img__inner"
              :style="{'height': siteInfo.configuration?.logoHeight ? siteInfo.configuration?.logoHeight + 'px' : '24px'}"
            >
                <img
                  :src="siteInfo.logo"
                  v-if="siteInfo.logo"
                />
                <img
                  src="https://v-play.coocaa.com/cfg/SaaS_products/dev_static_img/7dd2f574-969f-4b4d-b711-d9a9c754197f.png"
                  v-else
                />
            </span>
          </a>
        </div>
      </div>
      <div class="layout-header--center">
        <div class="menu-container">
          <div
            v-for="(item, index) in menu"
            :key="index"
            @mouseenter="($event) => handleMouseenter($event, item, index)"
            @mouseleave="handleMouseleave($event)"
            :class="{
              'menu-item': true,
              'is-stress': item.isStress,
              'is-cur':  isActive(item)//item.navUrl === isCurrentNav
            }"
          >
            <a
              class="nav-name"
              v-if="item.navUrl"
              :href="item.navUrl"
              :target="item.target === 1 ? '_blank' : ''"
            >
              {{item.navTitle}}
            </a>
            <a
              class="nav-name"
              v-else
            >{{item.navTitle}}</a>
            <div class="menu-item--category" v-if="item.children && item.children.length > 0">
              <DropdownMenu
                :items="item.children"
                :ref="'categoryOne' + index"
              ></DropdownMenu>
            </div>
          </div>
        </div>
        <div class="header-right--inlet" v-if="!(siteInfo.configuration?.showSearch)">
          <a href="/document" target="_blank">
            <div class="inlet-item document-item" :class="{ 'is-active': isDocument }">文档中心</div>
          </a>
          <a :href="`${consoleUrl}/dashboard`" target="_blank">
            <div class="inlet-item work-item">工作台</div>
          </a>
        </div>
      </div>
      <div class="user" v-if="!(siteInfo.configuration?.showSearch)">
        <div
          v-if="!user.name"
          class="inlet-item login-item"
          @click="handleLogin"
        >登录</div>
        <coocaa-popover
          v-else
          placement="bottom"
          title=""
          width="200"
          trigger="hover"
          :append-to-body="false"
        >
          <div slot="reference" class="user-info c-font-regular-14">
            <span class="user-avatar">
              <coocaa-avatar :size="18" :src="user.avatar" v-if="user.avatar"></coocaa-avatar>
              <coocaa-avatar :size="18" icon="el-icon-user" v-else></coocaa-avatar>
            </span>
            <span class="user-nickname">{{ user.name }}</span>
            <i class="el-icon-arrow-down"></i>
          </div>
          <div class="user-down--item" @click="handleCommand('myDocSpace')">我的空间</div>
          <div class="user-down--item" @click="handleCommand('loginout')">退出</div>
        </coocaa-popover>
      </div>
    </div>
    <SmallDropDownMenu
      :menu="menu"
      v-if="isMenuOpen"
    ></SmallDropDownMenu>

  </el-header>
</template>

<script>
// import { CHeader } from '@cseed/admin-toolkit'
import '@cseed/admin-toolkit/dist/admin-toolkit.css'
import DropdownMenu from './components/DropdownMenu.vue'
import { ssoLogin } from '@/services/login'
import SmallDropDownMenu from './components/SmallDropDownMenu.vue'
export default {
  components: {
    // CHeader,
    DropdownMenu,
    SmallDropDownMenu
  },
  computed: {
    isDocument () {
      return window.location.pathname.includes('/document')
    },
    user () {
      return this.$store.state.user
    },
    consoleUrl () {
      return process.env.VUE_APP_CONSOLE_URL
    },
    isCurrentNav () {
      const pathArray = this.$route.path.split('/')
      if (pathArray[1] === 'document') {
        return '/document'
      } else {
        return this.$route.path
      }
    }
  },
  props: ['menu', 'siteInfo', 'isSub'],
  data () {
    return {
      searchInput: '',
      logo: require('@/assets/images/logo.png'),
      isSmallSearch: false,
      isMenuOpen: false,
      homehref: '/?redirect_original=true'
    }
  },
  methods: {
    findNodeById (item) {
      const pathname = window.location.pathname
      const href = window.location.href
      if (item.navUrl === pathname || item.navUrl === href) {
        return item // 找到匹配的节点
      }

      if (item.children && item.children.length > 0) {
        for (const child of item.children) {
          const result = this.findNodeById(child)
          if (result) {
            return result
          }
        }
      }
      return null // 如果没有找到，返回 null
    },
    isActive (item) {
      return !!this.findNodeById(item)
    },
    handleLogin () {
      ssoLogin()
    },
    handleCommand (command) {
      if (command === 'loginout') {
        this.$ssoLogout()
      } else if (command === 'myDocSpace') {
        this.$router.push({ name: 'myDocSpace' })
      }
    },
    handleMouseenter (e, item, index) {
      // e.target.className = 'menu-item is-active'
      const categoryOneRef = this.$refs['categoryOne' + index]
      if (categoryOneRef && categoryOneRef[0]) {
        categoryOneRef[0].waterfallFlow()
        // categoryOneRef[0].cataChildren = item.children[0].children
        // categoryOneRef[0].currCate = item.children[0].name
      }
    },
    handleMouseleave (e) {
      // e.target.className = 'menu-item'
    },
    handleSearch () {
      window.open('/document/search/?from=header&q=' + this.searchInput)
    },
    handleShowSmallSearch () {
      this.isSmallSearch = true
      if (this.isMenuOpen) this.isMenuOpen = false
    },
    handleCloseSmallSearch () {
      this.isSmallSearch = false
    },
    handleOpenSmallMenu () {
      this.isMenuOpen = !this.isMenuOpen
    }
  }
}
</script>

<style lang="stylus" scoped>
.layout-header
  display flex
  align-items center
  position relative
  z-index 99
  background: var(--bg_3)
  padding 0 32px 0 16px
  height: 56px
  .header-logo
    height 22px
    line-height 22px
    font-size 15px
    padding-right 64px
    .logo__img
      color #606266
      .logo__img__inner
        display inline-block
        height 22px
        img
          height 100%
          vertical-align top
      .site-name
        display inline-block
        height 22px
        line-height 22px
        font-style normal
        padding-left 8px
        color #000
        margin-right 20px
  .layout-header--center
    text-align right
    display flex
    margin-left auto
    align-items center
    line-height 22px
  .user-info
    height 22px
    line-height 22px
    cursor pointer
    color var(--text_4)
    display flex
    .user-avatar
      padding-top 2px
      width 18px
      height 18px
    .user-nickname
      padding 0 8px
    .el-icon-arrow-down
      line-height 22px
// .login-off
//   >>>.user
//     display none
.header-right--inlet,
.user
  margin-left auto
  .inlet-item
    display inline-block
    margin-right 20px
    height 24px
    line-height 24px
    color #999
    font-size 12px
    padding 0 12px 0 28px
    background-color var(--fill_2)
    border 1px solid var(--fill_2)
    background-image url('~@/assets/images/document-icon1.png')
    background-repeat no-repeat
    background-position 12px 6px
    background-size 12px 12px
    border-radius 4px
    cursor pointer
    &:hover,
    &.is-active,
    // &.login-item
      color var(--brand_6)
      background-color var(--fill_6)
      border-color var(--brand_6)
      background-image url('~@/assets/images/document-icon2.png')
    &.login-item
      margin-right 0
  .work-item
    background-image url('~@/assets/images/work-icon1.png')
    &:hover
      background-image url('~@/assets/images/work-icon2.png')
.menu-container
  .menu-item
    display inline-block
    margin-right 32px
    height 56px
    line-height 56px
    .nav-name
      color var(--text_3)
      font-size 16px
      cursor pointer
      text-decoration none
    .menu-item--category
      display: none
      // visibility: visible;
      // visibility: hidden;
      // animation-duration: 1s;
    &:hover,
    &.is-cur
      position relative
      &:after
        content ''
        position absolute
        bottom 10px
        left 0
        right: 0
        height 2px
        background: var(--brand_6)
      .nav-name
        color var(--brand_6)
    &:hover
      .menu-item--category
        display: block
        position fixed
        width 100%
        left 0
        top 56px
        z-index: 100
        // background: #fff
    &.is-stress
      .nav-name
        background $AI-primary-fill-default
        background-clip text
        color transparent
      &:after
        display: none
>>>.layout-header--center
  flex 1
>>>.logo__img
  .logo
    vertical-align: top
.header
  line-height $nav-height
  padding 0px
  background: var(--bg_3)
  .user-down--item
    height 38px
    line-height 38px
    cursor pointer
  a.site-name
    display flex
    line-height $nav-height
    height $nav-height
    overflow hidden
    font-weight bold
    font-size 19px
    margin-left 0
    text-decoration none
    color #333
    margin-right 30px
    h1
      font-size 18px
      margin 0
      padding 0
      font-weight 600
    .logo
      background-size 100%
      height 60px
      line-height 60px
      margin-right 7px
      display flex
      align-items center
      img
        height 24px
      span
        display block
        text-indent -9999px
.menu
  flex 1
  ul
    display flex
    font-size 16px
    list-style-type none
    margin 0
    padding 0
    flex-wrap wrap
    justify-content flex-end
    li:last-child
      flex 1
      text-align left
      cursor unset
    li
      padding 0 12px
      margin 0
      cursor pointer
      .nav-name
        font-size 14px
        font-weight 500
        color #000000
        display block
        .nav-icon
          color #666
          font-weight bold
      a.nav-name
        font-size 14px
        font-weight 500
        color #000000
        text-decoration none
        &.is-stress
          background $AI-primary-fill-default
          background-clip text
          color transparent
      &:hover
        .nav-name
          display block
          position relative
          float left
          color $hover-color
      .is-cur
        color $hover-color !important
      .category
        width 100%
        height auto
        text-align left
        position absolute
        opacity 0
        left 0
        top $nav-height
        max-height 0
        z-index $highestIndex
        overflow hidden
        transition all 0.5s
        -moz-transition all 0.5s
        -webkit-transition all 0.5s
        -o-transition all 0.5s
        padding 0 20px
        box-sizing border-box
    li.is-active
      .category
        height auto
        background #fff
        max-height 100vh
        opacity 1
        transition all 0.5s
        -moz-transition all 0.5s
        -webkit-transition all 0.5s
        -o-transition all 0.5s
        box-shadow 5px 5px 5px rgba(0, 0, 0, 0.1)
.small-menu
  display none
  i
    background url('~@/assets/images/menu.png') no-repeat center
    background-size 100%
    display block
    width 28px
    height 60px
  span
    line-height 60px
    font-size 28px
.search
  margin-top 14px
  width 260px
  margin-left auto
  line-height 30px
.small-search
  display none
.developer
  font-size 14px
  font-weight 500
  margin-left 20px
  a
    text-decoration none
    color #000000
    &:hover
      color $hover-color
/deep/.el-input--small .el-input__inner
  font-size 14px
  border-color #D1D3D6
  &:focus
    border-color #7F9ADC
  &::placeholder
    color #C1CDEA
/deep/.el-input__prefix
  font-size 14px
.login
  margin-left 20px
  font-size 14px
  cursor pointer
  padding 0 20px
  background-color #486ff6
  color #ffffff
// .user
//   margin 0 20px
//   line-height 28px
//   cursor pointer
//   span
//     background #409EFF
//     display block
//     border-radius 20px
//     margin-top 14px
//     font-size 16px
//     color #fff
//     width 28px
//     height 28px
//     line-height 28px
//     text-align center
@media screen and (max-width $mobile-width)
  .layout-header
    padding 0 16px
    .site-name
      display: none
    >>>.menu-container,
    >>>.header-right--inlet
      display: none
    >>>.user
      display: none
    >>>.small-menu
      display: block
      margin-left auto
  .header.min-width
    min-width 100%
  // .menu
  //   display none
  // .small-menu
  //   display block
  // .navigation
  //   width 100%
  //   min-width 280px
  // .developer
  //   display none
  // .search
  //   display none
  // .small-search
  //   display block
  //   font-size 24px
  //   margin-left auto
  //   margin-right 14px
  // .header
  //   padding 0 14px
  // .login
  //   display none
  // .user
  //   display none
</style>
