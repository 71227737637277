<!-- 评论页面 -->
<template>
  <el-drawer
    direction="rtl"
    size="380px"
    custom-class="cms-comment-drawer-wrapper"
    :title="$t('site.comment')"
    :visible.sync="visible"
    :close-on-press-escape="false"
    append-to-body
    :wrapperClosable="false"
  >
    <el-scrollbar
      class="cms-comment-drawer-content"
      v-if="commentList.length"
    >
      <el-card
        class="cms-comment-box-card"
        v-for="(i,index) in commentList"
        :key="index"
      >
        <!-- <div slot="header" class="clearfix">
          <span>{{ user.name }}</span>
          <div class="cms-comment-oprate-btn">
            <el-button type="text" :title="$t('site.reply')">{{ $t('site.reply') }}</el-button>
            <el-button type="text" :title="$t('imgEdit.Delete')">{{ $t('imgEdit.Delete') }}</el-button>
          </div>
        </div> -->
        <div
          v-for="(item,k) in [i].concat(i.children)"
          :key="k"
          class="card-item"
        >
          <div style="display:inline-block;width:40px;vertical-align: top;">
            <el-avatar
              :size="34"
              :src="item.avatar"
              style="background:#ccc;"
            >
              <span
                class="app-avatar"
                style="font-size:16px"
              >
                {{ item['userNickname'] ? item['userNickname'].substring(0, 1) : '-' }}
              </span>
            </el-avatar>
          </div>
          <div
            style="display:inline-block;width:calc(100% - 40px);"
            class="cms-comment-item-text"
          >
            <div class="user-comment-top">
              <span class="user-name">{{ item.userNickname }}
                <span class="time">{{item.createTime}}</span>
              </span>
              <div class="cms-comment-oprate-btn">
                <el-button
                  type="text"
                  :title="$t('site.reply')"
                  @click="handelEdit(item.userNickname,index)"
                >{{ $t('site.reply') }}</el-button>
                <el-button
                  type="text"
                  :title="$t('imgEdit.Delete')"
                  v-if="user.uniqueId===item.userUniqueId"
                  @click="delComment(item,k)"
                >{{ $t('imgEdit.Delete') }}</el-button>
              </div>
            </div>
            <span class="user-content">{{item.content}}</span>
          </div>
        </div>
        <div class="user-comment-bottom clearfix">
          <el-input
            :maxlength="500"
            show-word-limit
            v-model="replayComment[index]"
            :ref="'replayComment'+index"
            clearable
            placeholder="输入评论"
          />
          <div
            class="comment-input-btns"
            v-if="replayComment[index]"
          >
            <el-button
              size="mini"
              @click="$set(replayComment, index,'')"
            >取 消</el-button>
            <el-button
              size="mini"
              type="primary"
              @click="submitReplayComment(i, index)"
            >发 送</el-button>
          </div>
        </div>
      </el-card>
    </el-scrollbar>
    <div class="comment-input">
      <el-input
        type="textarea"
        :rows="3"
        :maxlength="500"
        show-word-limit
        v-model="comment"
        clearable
        resize="none"
        placeholder="输入一条新评论"
      />
      <div
        class="comment-input-btns"
        v-if="comment"
      >
        <el-button @click="comment=''">取 消</el-button>
        <el-button
          type="primary"
          @click="submitComment"
        >发 送</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { getAllLevelComment, saveComment, deleteComment } from '@/services/comment'
import { guildSsoLogin } from '@/utils/common'
import { PAGE_TYPE_DOC, PAGE_TYPE_PAGE } from '@/utils/constants'
export default {
  props: {
    resourceUniqueId: { // 资源ID
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      visible: false,
      commentLen: 0, // 评论条数
      commentList: [], // 当前所有评论
      isLogin: false,
      resId: '', // 资源ID
      comment: '',
      replayComment: []
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    }
  },
  methods: {
    handelEdit (name, index) {
      this.$set(this.replayComment, index, '@' + name + ' ')
      // this.replayComment[index] = '@' + name + ' '
      this.$refs['replayComment' + index][0].focus()
    },
    initComment () {
      const params = {
        resourceType: this.$route.meta.pageType === PAGE_TYPE_PAGE ? 7 : 8, // PAGE_TYPE_PAGE 7,PAGE_TYPE_DOC 8
        resourceUniqueId: this.resId,
        page: 1,
        pageSize: 9999,
        sort: 1
      }
      getAllLevelComment(params).then(res => {
        this.commentLen = res.total
        this.commentList = res.list
        // console.log(res)
      })
    },
    handleOpenDrawer () {
      // $checkSSO 判断用户是否登录
      this.$checkSSO(this.$route).then(() => {
        this.isLogin = true
        this.comment = ''
        this.replayComment = []
        // 获取资源ID
        this.resId = this.resourceUniqueId
        // if (this.resourceUniqueId) {
        //   this.resId = this.resourceUniqueId
        // } else {
        //   this.resId = this.$route.meta.resId || this.$route.params[this.$route.meta.idKey]
        // }
        this.openDrawer()
      }).catch(() => {
        this.isLogin = false
        !this.$store.state.isLoginNotify && guildSsoLogin.call(this)
      })
    },
    openDrawer () {
      this.visible = true
      this.initComment()
      if (this.isLogin) {
        this.$nextTick(() => {
          this.comment = ''
        })
      }
    },
    submitComment () {
      const params = {
        resourceUniqueId: this.resId,
        resourceType: this.$route.meta.pageType === PAGE_TYPE_PAGE ? 7 : 8, // PAGE_TYPE_PAGE 7,PAGE_TYPE_DOC 8
        content: this.comment
      }
      saveComment(params).then(({ data }) => {
        this.initComment()
        this.comment = ''
      })
    },
    delComment (data, index) {
      let params = {}
      if (index === 0) {
        params = {
          commentId: data.commentId,
          resourceUniqueId: this.resId
        }
      } else {
        params = {
          answerId: data.answerId
        }
      }
      deleteComment(params).then(() => {
        this.initComment()
      })
    },
    submitReplayComment (data, index) {
      const params = {
        // commentId: index === 0 ? data.commentId : data.answerId,
        commentId: data.answerId || data.commentId,
        content: this.replayComment[index],
        firstLevelCommentId: this.commentList[index].commentId,
        userNicknameReplied: data.userNickname,
        resourceUniqueId: this.resourceUniqueId
      }
      saveComment(params).then(({ data }) => {
        this.initComment()
        this.replayComment = []
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
.cms-comment-drawer-wrapper
  position relative
  .cms-comment-drawer-content
    height calc(100% - 150px)
    box-sizing border-box
    .cms-comment-box-card
      position relative
      margin-bottom 20px
      &::before
        content ''
        position absolute
        top -2px
        left -1px
        right -1px
        border-top 6px solid $primary-color
        border-top-left-radius 6px
        border-top-right-radius 6px
      .card-item:hover .cms-comment-oprate-btn
        display inline-block
        .el-button--text
          padding 0
      .cms-comment-oprate-btn
        // display: inline-block
        // float: right
        display none
    >>>.el-scrollbar__wrap
      padding 0 15px
      overflow-x hidden
  .comment-input
    border-top 1px solid $folder-border-color
    padding 15px
    position absolute
    bottom 10
    left 0
    right 0
    .comment-input-btns
      text-align right
      margin-top 10px
  .user-comment-bottom
    .comment-input-btns
      text-align right
      margin-top 10px
  .cms-comment-item-text
    display flex
    flex-direction column
    justify-content space-between
    margin-bottom 12px
    .user-comment-top
      flex 1
      display flex
      justify-content space-between
      align-items center
      .user-name
        color $main-color
        font-weight bold
      .time
        color $minor-text
        font-size 12px
    .user-content
      padding 10px 0
      font-size 13px
      line-height 22px
</style>
