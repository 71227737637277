var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"margin-center--box"},[_c('div',{staticClass:"tenant-header--search"},[_c('coocaa-input',{staticClass:"search-input",attrs:{"placeholder":"请输入关键字进行搜索","size":"medium"},on:{"input":_vm.handleproductSetSearch},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"searchText"}}),_c('coocaa-button',{staticClass:"search-btn",attrs:{"type":"primary","size":"medium"},on:{"click":_vm.handleproductSetSearch}},[_vm._v("搜索")])],1),_c('div',{staticClass:"product-set--box"},[_c('el-scrollbar',{staticClass:"product-set--wrapper"},[(_vm.emptySearchText.length && !_vm.isEmpty)?_c('div',{staticClass:"search-result-box"},[_vm._v(" 共找到"),_c('span',[_vm._v(_vm._s(_vm.productNum))]),_vm._v("个与“"),_c('span',[_vm._v(_vm._s(_vm.emptySearchText))]),_vm._v("”相关的结果 ")]):_vm._e(),_c('div',{ref:"fallFlowBox",staticClass:"margin-center"},[(_vm.isEmpty)?_c('div',{staticClass:"empty-box"},[_c('div',{staticClass:"empty-icon"},[_c('img',{attrs:{"src":require("@/assets/images/empty1.png"),"alt":""}})]),_c('div',{staticClass:"empty-text"},[_vm._v("未找到与“"),_c('span',[_vm._v(_vm._s(_vm.emptySearchText))]),_vm._v("”相关的结果")])]):_vm._e(),_vm._l((_vm.copyItems),function(item,index){return _c('div',{key:index,ref:"fallFlowItem",refInFor:true,staticClass:"product-set-box",class:{
            'is-active': _vm.isGroupActive(item)
          }},[_c('div',{staticClass:"product-set--name c-font-medium-16 level-1"},[(item.navUrl)?_c('a',{attrs:{"href":item.navUrl,"target":item.target === 1 ? '_blank' : ''},domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(item.navTitle, _vm.emptySearchText))}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(item.navTitle, _vm.emptySearchText))}})]),(item.children && item.children.length > 0)?_c('div',{staticClass:"product-list"},_vm._l((item.children),function(towItem,towIndex){return _c('div',{key:towIndex,staticClass:"product-item--group",class:[towItem.children && towItem.children.length > 0 ? 'group-2' : 'group-3']},[_c('div',{class:[
                  'product-set--name',
                  `c-font-medium-${towItem.children && towItem.children.length > 0 ? 16 : 14}`,
                  `level-${towItem.children && towItem.children.length > 0 ? 2 : 3}`,
                  _vm.isActive(towItem) ? 'is-active' : ''
                ]},[(towItem.navUrl)?_c('a',{attrs:{"href":towItem.navUrl,"target":towItem.target === 1 ? '_blank' : ''},domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(towItem.navTitle, _vm.emptySearchText))}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(towItem.navTitle, _vm.emptySearchText))}})]),(towItem.children && towItem.children.length > 0)?_c('div',{staticClass:"product-list"},_vm._l((towItem.children),function(threeItem,threeIndex){return _c('div',{key:threeIndex,staticClass:"product-set--name c-font-medium-14 level-3",class:{
                    'is-active': _vm.isActive(threeItem)
                  }},[(threeItem.navUrl)?_c('a',{attrs:{"href":threeItem.navUrl,"target":threeItem.target === 1 ? '_blank' : ''},domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(threeItem.navTitle, _vm.emptySearchText))}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(threeItem.navTitle, _vm.emptySearchText))}})])}),0):_vm._e()])}),0):_vm._e()])})],2)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }