import { Message } from 'element-ui'
import { getSecurityUrl } from '@/utils/dom'
import { PAGE_TYPE_OTHER } from '@/utils/constants'
// import { ssoLogin } from '@/services/login'
import { getNavBindResource } from '@/services/common'
import find from 'lodash/find'
import transform from 'lodash/transform'
import { ssoLogin, getUserDetail } from '@/services/login'

export function getIsLogin () {
  this.$checkSSO(this.$route).then(() => {
    getUserDetail().then((res) => {
      debugger
    }).catch(err => {
      if (err?.response?.data?.code === 2005) {
        ssoLogin(this.$route.fullPath)
      }
    })
  }).catch(() => {
    ssoLogin(this.$route.fullPath)
  })
}

export function getPageInfo (route, isSdk) {
  const { params, meta } = route
  const { idKey, pageType } = meta || {}
  return {
    name: document.title,
    url: getSecurityUrl(),
    businessId: params[idKey] || '',
    pageTypeKey: isSdk ? 4 : pageType || PAGE_TYPE_OTHER
  }
}
/**
 * 根据节点uniqueId查找最顶级父节点数据
 * @param {*} param0
 * @returns
 */
export function findParentData ({ treeData = [], docId = '' } = {}) {
  // 查找当前ID的节点数据
  const nodePath = treeFindPath(treeData, node => node.uniqueId === docId)
  const nodeInfo = find(treeData, { uniqueId: nodePath[0] })
  return nodeInfo ? [nodeInfo] : []
}

/** 根据节点数据查找最顶级父节点 */
export function treeFindPath (treeData, func, path = []) {
  if (!treeData) return []
  for (const data of treeData) {
    path.push(data.uniqueId)
    if (func(data)) return path
    if (data.children) {
      const findChildren = treeFindPath(data.children, func, path)
      if (findChildren.length) return findChildren
    }
    path.pop()
  }
  return []
}

/**
 * 登录前的指导项
 */
export function guildSsoLogin (path) {
  this.$store.commit('SET_LOGIN_NOTIFY', true)
  const h = this.$createElement
  return this.$notify({
    title: '提示',
    duration: 1000 * 10,
    iconClass: 'el-icon-user-solid',
    offset: 60,
    onClose: () => {
      this.$store.commit('SET_LOGIN_NOTIFY', false)
    },
    message: h('div', {}, [
      h(
        'div',
        {
          style: {
            'font-size': '14px',
            padding: '5px 0 15px 0',
            color: '#486ff6'
          }
        },
        '您还未登录账号，请先登录'
      ),
      h(
        'div',
        {},
        [
          h('el-button', {
            props: {
              icon: 'el-icon-user-solid',
              size: 'small'
            },
            style: {
              background: '#486ff6',
              borderRadius: '5px',
              color: '#fff'
            },
            on: {
              click: () => {
                ssoLogin(path || this.$route.fullPath)
              }
            }
          }, '去登录吧')
        ]
      )
    ]
    )
  })
}

/**
 * 判断是手机端还是移动端
 */
export function isMobile () {
  if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    return true
  } else {
    return false
  }
}

/**
 *
 * 获取当前打开的主域名 支持IP
 * 如：coocaa.com  coocaa.net 172.20.151.198
 *
 */
export function getDomain () {
  const hostname = window.location.hostname
  const ipRegex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/
  const isIP = ipRegex.test(hostname)
  return isIP ? hostname : window.location.host.split('.').slice(-2).join('.')
}

// 区分环境生成cookie token name
export function getTokenName () {
  const hostname = window.location.hostname
  return hostname.indexOf('beta-') === 0 ? 'betaToken' : hostname.indexOf('dev-') === 0 ? 'devToken' : 'token'
}

// 设置cookie
export function setCookie (name, value, day, domain) {
  const date = new Date()
  date.setDate(date.getDate() + day)
  document.cookie = `${name}=${value};expires=${date};domain=${domain || window.location.hostname};path=/`
}

// 获取cookie
export function getCookie (name) {
  const reg = RegExp(name + '=([^;]+)')
  const arr = document.cookie.match(reg)
  if (arr) {
    return arr[1]
  } else {
    return ''
  }
}

// 删除cookie
export function delCookie (name) {
  setCookie(name, null, -1)
}

export function getUrlParams (queryString) {
  const searchParams = /([^&=]+)=([^&]*)/g
  const urlParams = {}
  let match
  // eslint-disable-next-line no-cond-assign
  while (match = searchParams.exec(queryString)) {
    urlParams[decodeURIComponent(match[1])] = decodeURIComponent(match[2])
  }
  return urlParams
}

/**
 * 遍历树，找到绑定资源的节点并请求资源列表
 * @param {*} treeList
 */
export function findTreeBindResource (siteInfo = {}) {
  const treeList = siteInfo.navList || []
  addLevel(treeList)
  findNavAndSendReq(treeList)
  return treeList
}

// 给树数据添加level
function addLevel (list, level = 1) {
  transform(list, (result, node) => {
    node.level = level
    result.push(node)
    if (node.children) {
      addLevel(node.children, level + 1)
    }
  }, [])
}

// 找到level=2的导航并查看是否绑定资源分类，如绑定 则发起请求
async function findNavAndSendReq (list) {
  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    if (item.level === 2 && item.resourceCategoryUniqueId) {
      const params = {
        uniqueId: item.resourceCategoryUniqueId
      }
      let result = await getNavBindResource(params)
      if (result && result.length) {
        result = result.map(item => {
          return {
            ...item,
            navTitle: item.name,
            navUrl: item.url,
            target: item.target || 1
          }
        })
        if (!item.children) item.children = []
        item.children.push(...result)
      }
    }
    item.children && item.children.length && findNavAndSendReq(item.children)
  }
}

export function isJSON (str) {
  try {
    JSON.parse(str)
    return true
  } catch (error) {
    return false
  }
}

export function str2Obj (str, preset) {
  let obj = preset
  try {
    obj = JSON.parse(str) || preset
  } catch (error) {
    console.error(error)
  }
  return obj
}
export function listToValueMap (list = [], { label = 'label', value = 'value' } = {}) {
  const map = {}
  list.forEach(ele => {
    map[ele[value]] = ele[label]
  })
  return map
}

function getFileInfo (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function (e) {
      resolve({
        size: file.size,
        dataUrl: e.target.result
      })
    }
    reader.readAsDataURL(file)
  })
}

function getImageInfo (dataUrl) {
  return new Promise(function (resolve, reject) {
    const image = new Image()
    image.onload = function () {
      resolve(image)
    }
    image.src = dataUrl
  })
}

export function beforeFileUpload ({ ext = [], isImg = true, maxSize, minSize, imgWidth, imgMaxWidth, imgMinWidth, imgHeight, imgMaxHeight, imgMinHeight }) {
  return (file) => {
    debugger
    return new Promise((resolve, reject) => {
      const error = (message) => {
        Message.warning(message)
        reject(message)
      }
      // 判断文件格式
      const fileExt = file.name.substring(file.name.lastIndexOf('.') + 1)
      const isValidExt = ext && ext.length ? ext.map(ele => ele.toLowerCase()).includes(fileExt.toLowerCase()) : true
      if (!isValidExt) return error('上传文件只能是 ' + ext + ' 格式!')
      // 判断文件大小
      const fileSize = file.size / 1024
      if (fileSize > maxSize) return error('上传文件大小不能超过 ' + maxSize / 1024 + 'MB!')
      if (fileSize < minSize) return error('上传文件大小不能小于 ' + maxSize / 1024 + 'MB!')
      // 图片格式判断
      if (isImg) {
        getFileInfo(file)
          .then(({ dataUrl }) => getImageInfo(dataUrl))
          .then(({ width, height }) => {
            if (imgWidth && width !== imgWidth) return error('图片尺寸宽度不符合要求：' + imgWidth + 'px')
            if (imgHeight && height !== imgHeight) return error('图片尺寸高度不符合要求：' + imgHeight + 'px')

            if (imgMaxWidth && width > imgMaxWidth) return error('图片宽度不能大于 ' + imgMaxWidth + 'px')
            if (imgMaxHeight && height > imgMaxHeight) return error('图片高度不能大于 ' + imgMaxHeight + 'px')

            if (imgMinWidth && width !== imgMinWidth) return error('图片宽度不能小于 ' + imgMinWidth + 'px')
            if (imgMinHeight && height !== imgMinHeight) return error('图片高度不能小于 ' + imgMinHeight + 'px')
            resolve()
          })
          .catch((e) => {
            console.error(e)
          })
      } else {
        resolve()
      }
    })
  }
}
