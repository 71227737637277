import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { merge } from 'lodash'
import CSeedLocale from '@cseed/locale'
import ElementLocale from 'element-ui/lib/locale'
Vue.use(VueI18n)

function loadMessages () {
  const locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

const i18n = new VueI18n({
  locale: 'zh',
  messages: merge({}, CSeedLocale.lang, loadMessages())
})

CSeedLocale.use(i18n)
ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n
