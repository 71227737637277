<template>
  <div class="img-edit">
    <div v-if="img.previewUrl" class="view-wrapper">
      <div class="view-mask" @click="handleEdit">
        <svg-icon icon-class="image_edit"></svg-icon>
        <span>{{$t('site.imageEdit')}}</span>
      </div>
      <el-image :src="img.previewUrl" class="view-img" fit="cover"></el-image>
    </div>
    <div v-if="isEdit" class="edit-wrapper">
      <span class="btn" @click="isEdit = false"><i class="icon close el-icon-close"></i></span>
      <span class="btn" @click="handleSaveImg"><i class="icon ok el-icon-check"></i></span>
      <ImageEditor ref="imgEdit" class="edit" :include-ui="useDefaultUI" :options="options"></ImageEditor>
    </div>
  </div>
</template>
<script>
import 'tui-color-picker/dist/tui-color-picker.css'
import 'tui-image-editor/dist/tui-image-editor.css'
import { ImageEditor } from '@toast-ui/vue-image-editor' // doc => https://nhn.github.io/tui.image-editor/latest/ImageEditor
import { getWindowHeightWidth, base64ToFile } from '@/utils/dom'
export default {
  components: {
    ImageEditor
  },
  props: {
    img: {
      type: Object,
      default () {
        return {
          previewUrl: '',
          file: ''
        }
      }
    }
  },
  data () {
    return {
      isEdit: false,
      useDefaultUI: true,
      options: {}
    }
  },
  methods: {
    handleEdit () {
      // 重新初始化 ImageEditor 配置
      this.options = {
        usageStatistics: false,
        includeUI: {
          // loadImage: {
          //   path: 'img/sampleImage.jpg',
          //   name: 'SampleImage'
          // },
          locale: {
            ZoomIn: this.$t('imgEdit.ZoomIn'),
            ZoomOut: this.$t('imgEdit.ZoomOut'),
            Hand: this.$t('imgEdit.Hand'),
            History: this.$t('imgEdit.History'),
            Undo: this.$t('imgEdit.Undo'),
            Redo: this.$t('imgEdit.Redo'),
            Reset: this.$t('imgEdit.Reset'),
            Delete: this.$t('imgEdit.Delete'),
            DeleteAll: this.$t('imgEdit.DeleteAll'),
            Shape: this.$t('imgEdit.Shape'),
            Rectangle: this.$t('imgEdit.Rectangle'),
            Circle: this.$t('imgEdit.Circle'),
            Triangle: this.$t('imgEdit.Triangle'),
            Fill: this.$t('imgEdit.Fill'),
            Stroke: this.$t('imgEdit.Stroke'),
            Crop: this.$t('imgEdit.Crop'),
            Custom: this.$t('imgEdit.Custom'),
            Square: this.$t('imgEdit.Square'),
            Apply: this.$t('imgEdit.Apply'),
            Cancel: this.$t('imgEdit.Cancel'),
            Draw: this.$t('imgEdit.Draw'),
            Free: this.$t('imgEdit.Free'),
            Straight: this.$t('imgEdit.Straight'),
            Color: this.$t('imgEdit.Color'),
            Range: this.$t('imgEdit.Range'),
            Text: this.$t('imgEdit.Text'),
            Bold: this.$t('imgEdit.Bold'),
            Italic: this.$t('imgEdit.Italic'),
            Underline: this.$t('imgEdit.Underline'),
            Left: this.$t('imgEdit.Left'),
            Right: this.$t('imgEdit.Right'),
            Center: this.$t('imgEdit.Center'),
            'Text size': this.$t('imgEdit.TextSize'),
            Change: this.$t('imgEdit.Change'),
            All: this.$t('imgEdit.All')
          },
          theme: {
            'common.bi.image': '' // 不加载LOGO图片
          }, // or whiteTheme
          menu: ['crop', 'draw', 'shape', 'text'], // ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'mask', 'filter'].
          initMenu: 'shape',
          uiSize: {
            width: '80vw',
            height: '100vh'
          },
          menuBarPosition: 'bottom'
        },
        cssMaxWidth: getWindowHeightWidth().w * 0.8 // 确保和 uiSize 的 width 保持一致
        // cssMaxHeight: 5000,
        // selectionStyle: {
        //   // cornerSize: 10,
        //   cornerStyle: 'circle', //  'rect' or 'circle'.
        //   cornerColor: '#fff',
        //   rotatingPointOffset: 70
        // }
      }
      this.isEdit = true // 显示 ImageEditor
      this.$nextTick(() => {
        // 当 ImageEditor 显示完毕后加载图片
        const imgEditInstance = this.$refs.imgEdit
        imgEditInstance.invoke('loadImageFromFile', this.img.file).then(() => {
          // ImageEditor 组件的BUG，手动加载图片后都要激活下菜单事件
          // issues => https://github.com/nhn/tui.image-editor/issues/477#issuecomment-721316190
          imgEditInstance.invoke('ui.activeMenuEvent')
        })
      })
    },
    handleSaveImg () {
      const imgEditInstance = this.$refs.imgEdit
      const imgBase64 = imgEditInstance.invoke('toDataURL', { format: 'png' }) // The format of the output image. Either "jpeg" or "png"
      const file = base64ToFile(imgBase64, 'png')
      this.$emit('img-change', { url: imgBase64, file })
      this.isEdit = false
    }
  }
}
</script>
<style lang="stylus" scoped>
$maskIndex = 1
$btnSize = 40px
$paddingSize = 10px
.img-edit
  width 360px
  height 180px
  .view-wrapper
    height 100%
    border 1px dashed $border-color
    border-radius 4px
    position relative
    overflow hidden
    .view-mask
      cursor pointer
      position absolute
      z-index $maskIndex
      left 0
      top 0
      right 0
      bottom 0
      background-color $mask-color
      color #fff
      display flex
      flex-direction column
      justify-content center
      align-items center
      .svg-icon
        font-size 32px
    .view-img
      width 100%
      height 100%
      background-color rgba(0, 0, 0, 0.1)
  .edit-wrapper
    position fixed
    z-index $maskIndex + 1
    background-color $mask-color
    top 0
    left 0
    width 100vw
    height 100vh
    .edit
      margin auto
    .btn
      cursor pointer
      position absolute
      right $btnSize
      top $btnSize
      display flex
      justify-content center
      align-items center
      width $btnSize
      height $btnSize
      font-size 24px
      border-radius 50%
      background-color $mask-color
      for num in (1..2)
        &:nth-child({num})
          top $btnSize * num + $paddingSize * (num - 1)
      .icon
        font-weight bold
      .close
        color $danger-color
      .ok
        color $success-color
</style>
