/*
 * @Date: 2023-02-16 14:48:25
 * @LastEditors: 'lidianbin' 'lidianbin@coocaa.com'
 * @LastEditTime: 2023-08-22 14:50:22
 * @FilePath: \cms_main_site\src\store\index.ts
 */
import Vue from 'vue'
import Vuex from 'vuex'
import { getDomain, setCookie, getTokenName } from '@/utils/common.js'
import Storage from '@/utils/storage'
Vue.use(Vuex)

const storageName = 'cmd-site'

export default new Vuex.Store({
  state: {
    site: '',
    user: {
      name: '',
      token: '',
      uniqueId: ''
    },
    isLoginNotify: false,
    isCreateChildData: false // 创建子文件时状态标识
  },
  mutations: {
    SET_SITE (state, site) {
      state.site = site
    },
    SET_USERINFO (state, user) {
      state.user = user
    },
    SET_LOGIN_NOTIFY (state, val) {
      state.isLoginNotify = val
    },
    SET_CHILD_STATUS (state, val) {
      state.isCreateChildData = val
    }
  },
  actions: {
    setSite ({ commit }, siteInfo) {
      commit('SET_SITE', siteInfo)
    },
    cacheUserInfo ({ commit }, user) {
      commit('SET_USERINFO', user)
      Storage.$set(`${storageName}/user`, user)
      setCookie(getTokenName(), user.token, 7, getDomain())
    },
    clearUserInfo ({ commit }) {
      const user = {
        name: '',
        uniqueId: '',
        identif: '',
        token: ''
      }
      commit('SET_USERINFO', user)
      Storage.$remove(`${storageName}/user`)
      setCookie(getTokenName(), null, -1, getDomain())
    }
  },
  modules: {
  }
})
