import { init, quick, registerPage } from '@cbee/bee-sdk-javascript'
init({
  server_url: `https://sc-clog.skysrt.com/sa?project=${process.env.NODE_ENV === 'development' ? 'default' : 'production'}`,
  is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
  use_client_time: true,
  send_type: 'beacon',
  batch_send: true, // 开启批量发送
  show_log: process.env.NODE_ENV === 'development',
  debug_mode: process.env.NODE_ENV === 'development',
  heatmap: {
    // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap: 'not_collect',
    // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    scroll_notice_map: 'not_collect'
  }
})
registerPage({
  productId: 'developer_cms'
})
quick('autoTrack') // 用于采集 $pageview 事件。
