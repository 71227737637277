import fetch from './fetch'

const prefix = 'admin'

/** 获取平台模板库 */
export function getAllLevelComment (params) {
  return fetch({
    method: 'GET',
    url: `${prefix}/techSpaceComment/getAllLevelComment`,
    params
  })
}
// 评论/admin/techSpaceComment/saveComment
export function saveComment (data) {
  return fetch({
    method: 'post',
    url: 'admin/techSpaceComment/saveComment',
    isJSON: true,
    data
  })
}
// 删除评论/admin/techSpaceComment/deleteComment
export function deleteComment (params) {
  return fetch({
    method: 'delete',
    url: 'admin/techSpaceComment/deleteComment',
    isJSON: true,
    params
  })
}
