<template>
  <el-footer
    height="auto"
    class="c-footer min-width"
  >
    <div
      class="foot-con min-width margin-center"
      v-if="!(siteInfo?siteInfo.configuration?.showFooterNav:'')"
    >
      <div class="foot-nav">
        <div class="foot-nav-top-title">
          <span class="main-title">咨询行业专业顾问</span>
          <span class="sub-title">关于酷开解决方案的更多咨询，请联系我们的销售和技术团队。</span>
        </div>
        <span
          class="foot-nav-btn"
          @click="openConsult"
        >
          <img
            :src="require('@/assets/images/footer-question.png')"
            alt="咨询"
          />
          立即咨询
        </span>
      </div>
      <div class="footer-card">
        <img :src="require('@/assets/images/footer-card.png')" />
      </div>
      <div class="contact">
        <img :src="require('@/assets/images/footer-logo.png')" style="height:35px"/>
        <ul
          class="contact-uls"
          v-for="(item, index) of navList"
          :key="index"
        >
          <li class="contact-li-main-title contact-li-nocursor">{{ item.label }}</li>
          <li
            v-for="(child, cIndex) of item.children"
            :key="cIndex"
            :class="['contact-uls-li', { 'contact-li-nocursor': !child.link }]"
            @click="child.link && openNavLink(child.link)"
          >
            {{ child.label }}
          </li>
        </ul>
      </div>
    </div>
    <div class="copyright">
      <div>© COOCAA · 深圳市酷开软件技术有限公司 · ICP备案证号： <a
          href="http://beian.miit.gov.cn/"
          target="_blank"
        >粤ICP备06098778号-10</a> |
        <a @click="openDoc(1)">法律声明</a>
        <a
          style="margin-left: 5px;"
          @click="openDoc(2)"
        >隐私政策</a>
      </div>
    </div>
  </el-footer>
</template>

<script>
export default {
  props: ['siteInfo'],
  data () {
    return {
      navList: [
        {
          label: '酷开科技',
          link: '',
          children: [
            { label: '公司介绍', link: 'https://www.coocaa.com/aboutus.html' },
            { label: '酷开科技', link: 'https://www.coocaa.com/play.html' },
            { label: '新媒体渠道', link: 'https://www.coocaa.com/aboutus.html#NewMediaChannel' }
          ]
        },
        {
          label: '合作伙伴',
          link: '',
          children: [
            { label: '合作品牌', link: 'https://www.coocaa.com/plan.html#planPage_Business' },
            { label: '经典案例', link: 'https://www.coocaa.com/innovalue.html#inlCon_cases' }
          ]
        },
        {
          label: '相关服务',
          link: '',
          children: [
            { label: '创维', link: 'http://www.skyworth.com/', className: 'contact-uls-li' },
            { label: '广告服务', link: 'https://www.coocaa.com/ad-service.html', className: 'contact-uls-li' }
          ]
        },
        {
          label: '联系我们',
          link: '',
          children: [
            { label: '客户服务与投诉热线：400-1688-880', link: '' },
            { label: '商务合作：bdc@coocaa.com', link: '' },
            { label: '人才招聘：zhaopin@coocaa.com', link: '' }
          ]
        }
      ]
    }
  },
  methods: {
    openConsult () {
      window.open(this.$router.resolve({ name: 'consultFormPage' }).href, '_blank')
    },
    openDoc (type) {
      window.open(type === 1 ? process.env.VUE_APP_TERMSERVICE : process.env.VUE_APP_PRIVACYAGREEM, '_blank')
    },
    openNavLink (link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.c-footer
  background rgba(47, 71, 115, 1)
  padding 0
.foot-con
  padding 73px 10px 10px 10px
  box-sizing border-box
  position relative
  max-width 1208px
  .footer-card
    position absolute
    top -30px
    right 0
  .foot-nav
    max-width 642px
    display flex
    justify-content space-around
    align-items center
    margin-bottom 117px
    .foot-nav-top-title
      flex 1
      display flex
      flex-direction column
      justify-content center
      align-items flex-start
      .main-title
        font-size 20px
        line-height 30px
        font-weight 500
        color #fff
      .sub-title
        font-size 16px
        line-height 28px
        font-weight 400
        color rgba(255, 255, 255, 0.8)
        margin-top 2px
    .foot-nav-btn
      color #fff
      background rgba(21, 94, 255, 1)
      padding 9px 20px
      border-radius 4px
      box-shadow 6px 9px 40px 0 rgba(63, 60, 255, 0.44)
      font-size 14px
      cursor pointer
      img
        vertical-align bottom
  .contact
    max-width 1208px
    margin-bottom 48px
    display flex
    justify-content space-between
    align-items flex-start
    .contact-uls
      display flex
      flex-direction column
      justify-content space-between
      align-items flex-start
      .contact-uls-li, .contact-li-nocursor
        color rgba(255, 255, 255, 0.6)
        cursor pointer
        margin-bottom 24px
        &:first-child
          color #fff
        &:last-child
          margin-bottom 0
      .contact-li-nocursor
        cursor default
.copyright
  line-height 22px
  padding 32px 0
  font-size 14px
  color rgba(255, 255, 255, 0.6)
  align-items center
  display flex
  justify-content center
  border-top 1px solid rgba(255, 255, 255, 0.1)
  .foot-logo
    display inline-block
    margin-right 20px
  a
    color rgba(255, 255, 255, 0.6)
    text-decoration none
    cursor pointer
    &:hover
      color #fff
@media screen and (max-width $mobile-width)
  .c-footer
    min-width 280px
  .foot-con
    padding 14px 0
    width 100%
    flex-wrap wrap
    min-width 280px
    font-size 12px
    .foot-nav
      display none
    .footer-card
      display none
    .contact
      width 100%
      padding 0 14px
      box-sizing border-box
      margin-bottom 0
      flex-direction column
      .contact-uls
        flex-direction row
        flex-wrap wrap
        justify-content flex-start
        padding-top 10px
        &:first-of-type
          padding-top 20px
        .contact-li-nocursor
          width 100%
        .contact-li-main-title
          font-size 14px
        .contact-uls-li
          margin-right 24px
  .copyright
    font-size 12px
    padding 14px
</style>
