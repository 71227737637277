<!--
 * @Date: 2023-02-16 14:48:25
 * @LastEditors: 'lidianbin' 'lidianbin@coocaa.com'
 * @LastEditTime: 2024-09-04 20:05:44
 * @FilePath: \cms_main_site\src\components\layout\components\DropdownMenu.vue
-->
<template>
  <div class="margin-center--box">
    <div class="tenant-header--search">
      <coocaa-input
        v-model.trim="searchText"
        placeholder="请输入关键字进行搜索"
        class="search-input"
        size="medium"
        @input="handleproductSetSearch"
      ></coocaa-input>
      <coocaa-button
        type="primary"
        class="search-btn"
        size="medium"
        @click="handleproductSetSearch"
      >搜索</coocaa-button>
    </div>
    <div class="product-set--box">
      <el-scrollbar class="product-set--wrapper">
        <div
          class="search-result-box"
          v-if="emptySearchText.length && !isEmpty"
        >
          共找到<span>{{ productNum }}</span>个与“<span>{{ emptySearchText }}</span>”相关的结果
        </div>
        <div class="margin-center" ref="fallFlowBox">
          <div class="empty-box" v-if="isEmpty">
            <div class="empty-icon">
              <img src="~@/assets/images/empty1.png" alt="" />
            </div>
            <div class="empty-text">未找到与“<span>{{ emptySearchText }}</span>”相关的结果</div>
          </div>

          <div class="product-set-box"
            v-for="(item, index) in copyItems"
            :key="index"
            ref="fallFlowItem"
            :class="{
              'is-active': isGroupActive(item)
            }"
          >
            <!-- 第一级 -->
            <div class="product-set--name c-font-medium-16 level-1"
            >
              <a
                :href="item.navUrl"
                v-if="item.navUrl"
                :target="item.target === 1 ? '_blank' : ''"
                v-html="highlightKeyword(item.navTitle, emptySearchText)"
              ></a>
              <span v-else v-html="highlightKeyword(item.navTitle, emptySearchText)"></span>
            </div>
            <div
              class="product-list"
              v-if="item.children && item.children.length > 0"
            >
              <!-- 第二级 -->
              <div
                class="product-item--group"
                :class="[towItem.children && towItem.children.length > 0 ? 'group-2' : 'group-3']"
                v-for="(towItem, towIndex) in item.children"
                :key="towIndex"
              >
                <div
                  :class="[
                    'product-set--name',
                    `c-font-medium-${towItem.children && towItem.children.length > 0 ? 16 : 14}`,
                    `level-${towItem.children && towItem.children.length > 0 ? 2 : 3}`,
                    isActive(towItem) ? 'is-active' : ''
                  ]"
                >
                  <a
                    :href="towItem.navUrl"
                    v-if="towItem.navUrl"
                    :target="towItem.target === 1 ? '_blank' : ''"
                    v-html="highlightKeyword(towItem.navTitle, emptySearchText)"
                  ></a>
                  <span v-else v-html="highlightKeyword(towItem.navTitle, emptySearchText)"></span>
                </div>
                <!-- 第三级 -->
                <div
                  class="product-list"
                  v-if="towItem.children && towItem.children.length > 0"
                >
                  <div
                    class="product-set--name c-font-medium-14 level-3"
                    v-for="(threeItem, threeIndex) in towItem.children"
                    :key="threeIndex"
                    :class="{
                      'is-active': isActive(threeItem)
                    }"
                  >
                    <a
                      :href="threeItem.navUrl"
                      v-if="threeItem.navUrl"
                      :target="threeItem.target === 1 ? '_blank' : ''"
                      v-html="highlightKeyword(threeItem.navTitle, emptySearchText)"
                    ></a>
                    <span v-else v-html="highlightKeyword(threeItem.navTitle, emptySearchText)"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>

// 定义产品集类
class ProductSet {
  constructor (name, children) {
    this.navTitle = name
    this.children = children
  }
}

// 定义树形数据节点类
class TreeNode {
  constructor (name, children = []) {
    this.name = name
    this.children = children
  }
}
export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      align: 'left',
      searchText: '',
      emptySearchText: '',
      isEmpty: false,
      copyItems: []
    }
  },
  computed: {
    productNum () {
      const nodes = this.copyItems
      return this.countNodes(nodes)
    }
  },
  methods: {
    findNodeById (item) {
      const pathname = window.location.pathname
      const href = window.location.href
      if (item.navUrl === pathname || item.navUrl === href) {
        return item // 找到匹配的节点
      }

      if (item.children && item.children.length > 0) {
        for (const child of item.children) {
          const result = this.findNodeById(child)
          if (result) {
            return result
          }
        }
      }
      return null // 如果没有找到，返回 null
    },
    isGroupActive (item) {
      return !!this.findNodeById(item)
    },
    isActive (item) {
      return !!this.findNodeById(item)
      // const pathname = window.location.pathname
      // const href = window.location.href
      // const navUrl = item.navUrl
      // return navUrl === pathname || navUrl === href
    },
    highlightKeyword (text, keyword) {
      if (!keyword) return text
      const regex = new RegExp(keyword, 'gi')
      const matches = text.match(regex)
      if (!matches) return text
      return text.replace(regex, match => `<span class="highlight">${match}</span>`)
    },
    countNodes (tree) {
      let count = 0
      function traverse (node) {
        if (!node.children || node.children.length === 0) {
          count++
        } else {
          node.children.forEach(child => traverse(child))
        }
      }
      tree.forEach(node => traverse(node))
      return count
    },
    /**
     * 搜索产品集
     * 如果搜索框有值，则搜索产品集，否则显示全部产品集
     * 如果当前分组名称包含搜索值，则显示该分组下所有产品
     * 否则对当前分组下子集进行过滤
     *
    */
    handleproductSetSearch () {
      const searchText = this.searchText.trim()
      if (searchText) {
        this.copyItems = this.search(searchText)
      } else {
        this.copyItems = JSON.parse(JSON.stringify(this.items))
      }
      this.isEmpty = !this.copyItems.length
      this.emptySearchText = `${searchText}`
      this.$nextTick(() => {
        this.waterfallFlow()
      })
    },
    // 搜索函数
    search (keyword, groupNavTitle = null) {
      const result = []
      const data = this.items
      for (let i = 0; i < data.length; i++) {
        const item = data[i]
        if (groupNavTitle === null || item.navTitle === groupNavTitle) {
          // 当前分组和搜索关键词匹配，将当前分组及其子集加入结果
          if (keyword && item.navTitle.indexOf(keyword) >= 0) {
            result.push(item)
          } else if (item.children && item.children.length > 0) {
            // 当前分组和搜索关键词不匹配，对当前分组的子集进行过滤
            const filteredChildren = this.filterChildren(item.children, keyword)
            if (filteredChildren.length > 0) {
              result.push(new ProductSet(item.navTitle, filteredChildren))
            }
          }
        }
      }
      return result
    },

    // 对子集进行过滤的函数
    filterChildren (children, keyword) {
      const result = []
      for (let i = 0; i < children.length; i++) {
        const child = children[i]
        if (keyword && child.navTitle.indexOf(keyword) >= 0) {
          result.push(child)
        } else if (child.children && child.children.length > 0) {
          const filteredChildren = this.filterChildren(child.children, keyword)
          if (filteredChildren.length > 0) {
            result.push(new ProductSet(child.navTitle, filteredChildren))
          }
        }
      }
      return result
    },

    getMinIndex (arr) {
      let min = arr[0]
      let index = 0
      for (let i = 0; i < arr.length; i++) {
        if (min > arr[i]) {
          min = arr[i]
          index = i
        }
      }
      return {
        value: min,
        index
      }
    },
    getMaxIndex (arr) {
      let max = arr[0]
      let index = 0
      for (let i = 0; i < arr.length; i++) {
        if (max < arr[i]) {
          max = arr[i]
          index = i
        }
      }
      return {
        value: max,
        index
      }
    },
    waterfallFlow () {
      const fallFlowBox = this.$refs.fallFlowBox
      // const fallFlowBoxWidth = fallFlowBox.offsetWidth
      // debugger
      const columns = 4
      const fallFlowItemWidth = 176
      const fallFlowItem = this.$refs.fallFlowItem || []
      const arr = []
      for (let i = 0; i < columns; i++) {
        arr.push(0)
      }
      const fallFlowItemLen = fallFlowItem.length
      fallFlowItem.forEach((item, index) => {
        const minIndex = this.getMinIndex(arr)
        const itemHeight = item.offsetHeight
        const top = minIndex.value
        arr[minIndex.index] = top + itemHeight + 24
        item.style.top = `${top}px`
        item.style.left = `${minIndex.index * fallFlowItemWidth + 88 * minIndex.index}px`
        if (fallFlowItemLen === index + 1) {
          fallFlowBox.style.height = `${this.getMaxIndex(arr).value}px`
        }
      })
    }
  },
  created () {
    this.copyItems = JSON.parse(JSON.stringify(this.items))
    this.$nextTick(() => {
      this.waterfallFlow()
    })
  }
}
</script>

<style lang="stylus" scoped>
.search-result-box
  width 952px
  margin 0 auto
  text-align left
  color var(--text_4)
  font-size 12px
  line-height 20px
  padding-bottom 10px
  padding-left 16px
  span
    color var(--brand_6)
    font-weight bold
    padding 0 3px
.empty-box
  width 100%
  padding-top 200px
  .empty-icon
    width 172px
    margin 0 auto
    img
      width 100%
  .empty-text
    color var(--text_5)
    font-size 12px
    padding-top 12px
    text-align center
    span
      color var(--brand_6)
      font-weight bold
.margin-center--box
  width 100%
  background-image url('~@/assets/images/menu-item--category.png')
  background-size cover
  background-repeat no-repeat
  background-position left top
  background-color: var(--bg_1)
  height: 700px
  box-shadow 0 10px 14px 0 rgba(45, 51, 64, .15)
  .tenant-header--search
    display flex
    height: 32px
    width: 968px
    margin: 0 auto
    padding 24px 0 20px 0
    .search-input
      width 360px
      margin-right 8px
      >>>.coocaa-input__inner
        border-color var(--border_3)
        vertical-align top
        padding 0 16px
    .search-btn
      width 80px
  .product-set--box
    height calc(100% - 80px)
    width 968px
    margin 0 auto
    // overflow-y auto
    .product-set--wrapper
      height 100%
      >>>.el-scrollbar__bar.is-horizontal
        display: none
    .margin-center
      width: 968px
      margin: 0 auto
      text-align: left
      position relative
      .product-set-box
        width 160px
        padding 8px
        border-radius 8px
        position absolute
        .group-2
          margin-bottom 20px
          &:last-child
            margin-bottom 0
        .product-set--name a
          text-decoration none
        .level-1
          color var(--brand_6)
          margin-bottom: 24px
          padding 0 8px
          a
            color var(--brand_6)
        .level-2
          color var(--text_1)
          margin-bottom: 12px
          padding 0 8px
          a
            color var(--text_1)
        .level-3
          margin-bottom 8px
          height: 22px
          overflow hidden
          a,
          span
            cursor pointer
            color var(--text_4)
            padding 0 8px
            display: block
            border-radius 4px
            &:hover
              background: var(--brand_8)
              color var(--brand_6)
        &:hover,
        &.is-active
          background: var(--bg_5)
      >>>.highlight
        color var(--danger_6) !important
.margin-center--box .product-set--box .margin-center .product-set-box .product-set--name.is-active a
  background: var(--brand_8)
  color var(--brand_6)
</style>
