<template>
  <div class="screenshot">
    <el-tooltip v-if="isSupportScreenshot" placement="top-start" :content="$t('site.screenshotPermisson')">
      <el-button type="primary" plain :disabled="isDomScreenshotLoading" @click="handleScreenshot">{{ $t('site.autoScreenshotBtn') }}</el-button>
    </el-tooltip>
    <el-button v-if="filePreviewUrl" type="danger" plain :disabled="isDomScreenshotLoading" @click="handleDelFile">{{ $t('site.clearScreenshotBtn') }}</el-button>
    <div class="mt10">
      <ImageEditorDialog v-if="filePreviewUrl" :img="{ previewUrl: filePreviewUrl, file }" @img-change="changeFile" class="preview"></ImageEditorDialog>
      <el-upload
        v-else
        v-loading="isDomScreenshotLoading"
        :element-loading-text="$t('site.pageCaptureLoading')"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        ref="elUpload"
        class="upload"
        drag
        :accept="fileLimit.accept"
        :auto-upload="false"
        :multiple="false"
        :on-change="onFileChange"
        action="">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          <span>将文件拖到此处，或<em>点击上传</em></span>
          <div>（只能上传jpg/png文件，且不超过5mb）</div>
        </div>
      </el-upload>
    </div>
  </div>
</template>

<script>
import { getObjectURL, getWindowHeightWidth, base64ToFile, appDomScreenshot } from '@/utils/dom'
import ImageEditorDialog from '@/components/ImgEditDialog.vue'
export default {
  components: { ImageEditorDialog },
  model: {
    prop: 'file',
    event: 'change'
  },
  props: {
    file: File
  },
  data () {
    return {
      isDomScreenshotLoading: false,
      isSupportScreenshot: navigator.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices, // 判断当前浏览器是否支持屏幕共享
      fileLimit: {
        size: 5, // 文件大小限制 5M
        unit: 'M',
        accept: 'image/png,image/jpg,image/jpeg,image/gif,image/webp',
        ext: ['.png', '.jpg', '.jpeg', '.gif', '.webp']
      },
      filePreviewUrl: ''
    }
  },
  watch: {
    file (val) {
      if (!val) {
        this.filePreviewUrl = ''
      }
    }
  },
  methods: {
    formatElUploadFileInfo (elUploadFile) {
      return {
        objectURL: getObjectURL(elUploadFile.raw),
        size: elUploadFile.size / 1024 / 1024,
        ext: elUploadFile.name.substring(elUploadFile.name.lastIndexOf('.')).toLowerCase()
      }
    },
    // 文件改变时
    onFileChange (file) {
      const { size, ext, objectURL } = this.formatElUploadFileInfo(file) // 格式化文件
      this.$refs.elUpload.clearFiles() // 清空文件列表
      // 文件大小判断
      if (size > this.fileLimit.size) {
        return this.$message.warning(this.$t('site.fileLimitSize', { size: this.fileLimit.size, unit: this.fileLimit.unit }))
      }
      // 文件类型判断
      if (this.fileLimit.ext.findIndex(ele => ele === ext) === -1) {
        return this.$message.warning(this.$t('site.fileLimitType'))
      }
      this.changeFile({ url: objectURL, file: file.raw })
    },

    // 屏幕截图
    handleScreenshot () {
      // 开启屏幕共享
      navigator.mediaDevices.getDisplayMedia({ video: true, audio: false, preferCurrentTab: true }).then(stream => {
        // 获取当前浏览器宽高
        const { w, h } = getWindowHeightWidth()
        // 将视频流设置到video中
        let videoElem = document.createElement('video')
        videoElem.style.width = `${w}px`
        videoElem.style.height = `${h}px`
        videoElem.autoplay = true
        videoElem.srcObject = stream

        this.$emit('before-screenshot', () => {
          // 延时 200 毫秒
          setTimeout(() => {
            // 使用 canvas 生成 base64 图片
            const canvas = document.createElement('canvas')
            canvas.width = w
            canvas.height = h
            canvas.getContext('2d').drawImage(videoElem, 0, 0, w, h)
            const imgBase64 = canvas.toDataURL('image/png')

            this.changeFile({
              url: imgBase64,
              file: base64ToFile(imgBase64, 'png')
            })

            // 关闭共享屏幕
            const tracks = videoElem.srcObject.getTracks()
            tracks.forEach(track => track.stop())
            videoElem.srcObject = null
            videoElem = null

            this.$emit('succsess-screenshot')
          }, 200)
        })
      }).catch(() => {
        this.$message.error(this.$t('site.screenshotError'))
      })
    },
    // 清空截图
    handleDelFile () {
      this.changeFile({
        url: '',
        file: undefined
      })
    },
    changeFile ({ url, file }) {
      this.filePreviewUrl = url
      this.$emit('change', file)
    }
  },
  mounted () {
    if (this.file) {
      this.filePreviewUrl = getObjectURL(this.file)
      return
    }
    this.isDomScreenshotLoading = true
    appDomScreenshot().then(data => {
      this.changeFile(data)
    }).finally(() => {
      this.isDomScreenshotLoading = false
    })
  }
}
</script>

<style lang="stylus" scoped>
.screenshot
  .upload
    width 100%
    :deep(.el-upload),
    :deep(.el-upload-dragger)
      width 100%
  .preview
    width 100%
</style>
