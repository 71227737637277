import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)
async function beforeEach (this: any, to: any, from: any, next: any) {
  const app = this.app

  // 等待sso校验完成再去请求接口
  try {
    await app.$checkSSO(to)
  } catch (err) {
    console.log('接口放行', err)
  }

  if (to.name === 'siteError') return next()
  app.$siteInitData().then(() => {
    next()
  }).catch(() => {
    next({ name: 'siteError' })
  })
}
function afterEach (this: any, to: any) {
  const app = this.app
  const breadcrumb = to.matched.slice(0).map((route: any) => {
    return {
      name: route.meta.title,
      to: route.name
        ? route.name
        : undefined
    }
  })
  app.$nextTick(() => {
    app.$bus.$emit('breadcrumb-change', breadcrumb)
  })
}
const router = new VueRouter({
  routes,
  base: '/',
  mode: 'history'
})
router.beforeEach(beforeEach.bind(router))
router.afterEach(afterEach.bind(router))

export default router
