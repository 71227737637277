<template>
  <div id="app">
    <router-view class="content-wrapper"/>
    <right-floating-layer ref="rightFL"></right-floating-layer>
  </div>
</template>
<script>
import rightFloatingLayer from '@/components/layout/RightFloatingLayer.vue'
export default {
  components: {
    rightFloatingLayer
  }
}
</script>
<style lang="stylus" scoped>
.content-wrapper
   min-width 1200px
@media screen and (max-width $mobile-width)
 .content-wrapper
   min-width auto
</style>
