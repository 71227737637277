import fetch from './fetch'
export function getSiteInfo (params) {
  return fetch({
    method: 'get',
    url: 'dev_cms/release/api/site/infoByDomain',
    params
  })
}
export function getSiteInfoByPage (params) {
  return fetch({
    method: 'get',
    url: 'dev_cms/release/api/site/infoByPage',
    params
  })
}

export function getNavBindResource (params) {
  return fetch({
    method: 'get',
    url: 'dev_cms/release/templateResourceCategories/relateResources',
    params
  })
}

/** 发送验证码 */
export function sendValidCode (params) {
  return fetch({
    method: 'get',
    url: 'auth/user/getSmsCode',
    params
  })
}

/** 提交咨询业务 */
export function submitConsult (data) {
  return fetch({
    method: 'post',
    url: 'dev_cms/release/consultations/submit',
    data
  })
}
