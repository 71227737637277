import fetch from './fetch'
// 反馈的问题类型
export function getFeedbackTypeService () {
  return fetch({
    method: 'get',
    url: 'dev_cms/admin/constants',
    params: { type: 'questionType' }
  })
}
// 问题反馈
export function saveFeedbackService (data) {
  return fetch({
    method: 'post',
    url: 'dev_cms/release/questionnaires',
    data
  })
}

// 页面点赞
export function pageLikeService (data) {
  return fetch({
    method: 'post',
    url: 'dev_cms/release/evaluates',
    data,
    isLoading: false
  })
}

export function uploadFeedbackFile (file) {
  const formdata = new FormData()
  formdata.append('path', 'dev_cms/feedback')
  formdata.append('orginalName', 0) // 1: 原始文件名上传(暂不支持中文); 0: 自动生成文件名上传;
  formdata.append('file', file)
  return fetch({
    method: 'post',
    url: 'dev_cms/release/images',
    data: formdata
  })
}
// 页面点赞
export function myFeedbackList (params) {
  return fetch({
    method: 'get',
    url: 'dev_cms/release/questionnaires/list',
    params,
    isLoading: false
  })
}
// 回复评分
export function feedbackListGrade (data) {
  return fetch({
    method: 'post',
    url: 'dev_cms/release/questionnaires/grade',
    data,
    isLoading: false
  })
}
