<!--
 * @Date: 2023-02-16 14:48:25
 * @LastEditors: 'lidianbin' 'lidianbin@coocaa.com'
 * @LastEditTime: 2024-08-23 21:54:14
 * @FilePath: \cms_main_site\src\components\layout\components\SmallDropDownMenu.vue
-->
<template>
  <div class="s-downmenu">
    <el-scrollbar style="height: calc(100vh - 60px)">
      <el-menu
        default-active="2"
        class="s-downmenu-main"
        @open="handleOpen"
        @close="handleClose">
        <SmallMenuItem :sub-menu="menu"></SmallMenuItem>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import SmallMenuItem from './SmallMenuItem.vue'
export default {
  components: {
    SmallMenuItem
  },
  props: ['menu'],
  data () {
    return {
      activeNames: ['1']
    }
  },
  methods: {
    handleOpen (val) {

    },
    handleClose () {
    }
  },
  created () {
  }
}
</script>

<style lang="stylus" scoped>
.s-downmenu
  position absolute
  width 100%
  max-height calc(100vh - 56px)
  top 56px
  left 0
  z-index $highestIndex
  overflow hidden
/deep/.el-submenu__title
  overflow hidden
</style>
