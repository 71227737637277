import html2canvas from 'html2canvas'

export function getObjectURL (file) {
  var url = null
  if (window.createObjectURL !== undefined) { // basic
    url = window.createObjectURL(file)
  } else if (window.URL !== undefined) { // mozilla(firefox)
    url = window.URL.createObjectURL(file)
  } else if (window.webkitURL !== undefined) { // webkit or chrome
    url = window.webkitURL.createObjectURL(file)
  }
  return url
}

export function getWindowHeightWidth () {
  return {
    w: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
    h: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
  }
}

export function base64ToFile (base64, ext) {
  const arr = base64.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], `${new Date().getTime()}.${ext}`, { type: mime })
}

export function getSecurityUrl () {
  const params = window.location.search.substring(1)
  let paramsArr = []
  if (params) {
    paramsArr = params.split('&').map((item, index) => {
      const itemArr = item.split('=')
      return {
        key: itemArr[0],
        value: itemArr[1]
      }
    })
  }
  const noToken = paramsArr.filter((item) => {
    return item.key !== 'responseToken'
  })
  let url = ''
  if (noToken.length > 0) {
    let paramsRes = ''
    noToken.forEach((item, index) => {
      if (index < (noToken.length - 1)) {
        paramsRes += (item.key + '=' + item.value + '&')
      } else {
        paramsRes += (item.key + '=' + item.value)
      }
    })
    url = window.location.origin + window.location.pathname + '?' + paramsRes
  } else {
    url = window.location.origin + (window.location.pathname === '/' ? '' : window.location.pathname)
  }
  return url
}

function getPageScroll () {
  let xScroll, yScroll
  if (self.pageYOffset) {
    yScroll = self.pageYOffset
    xScroll = self.pageXOffset
  } else if (
    document.documentElement &&
    document.documentElement.scrollTop
  ) {
    // Explorer 6 Strict
    yScroll = document.documentElement.scrollTop
    xScroll = document.documentElement.scrollLeft
  } else if (document.body) {
    // all other Explorers
    yScroll = document.body.scrollTop
    xScroll = document.body.scrollLeft
  }
  return { xScroll, yScroll }
}

export function appDomScreenshot () {
  const { yScroll } = getPageScroll()
  // 可视区域的高度
  const height =
        document.documentElement.clientHeight === 0
          ? document.body.clientHeight
          : document.documentElement.clientHeight
  return new Promise((resolve, reject) => {
    html2canvas(document.getElementById('app'), {
      // width: test.clientWidth, // DOM原始宽度
      height: height,
      y: yScroll, // canvas开始的的Y坐标
      scrollY: yScroll, // 滚动
      // allowTaint: true,
      // foreignObjectRendering: true,
      proxy: '/res-proxy'
      // scrollY: 0
    }).then(canvas => {
      const objectURL = canvas.toDataURL('image/webp', 0.5)
      const file = base64ToFile(objectURL, 'webp')
      resolve({ url: objectURL, file: file })
    }).catch(reject)
  })
}
