<template>
  <div class="feedback" data-html2canvas-ignore v-show="!isAutoScreenshot">
    <el-drawer
      v-if="isLogin"
      style="top:60px;"
      :modal="false"
      :modal-append-to-body="false"
      :title="$t('site.feedback')"
      :visible.sync="drawerVisible"
      @closed="handleClosed"
    >
      <div class="drawer-title" slot="title">
        <div
          v-for="(item, index) in titleMap"
          :class="titleTabIndex === index ? item.class + ' cur' : item.class"
          @click="($event) => handleTitleChange($event, item.class, index)"
          :key="index"
          >
          {{ item.label }}
        </div>
      </div>
      <div class="drawer-body">
        <el-form ref="feedbackForm" :model="feedbackForm" :rules="feedbackRules" label-width="80px" v-if="titleTabIndex === 0">
          <el-form-item :label="$t('site.questionKey')" prop="questionKey" class="question-type">
            <el-radio-group v-model="feedbackForm.questionKey">
              <el-radio v-for="questionType in questionTypeOptions" :key="questionType.key" :label="questionType.key">{{questionType.value}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="$t('site.questionDes')" prop="advice">
            <el-input v-model="feedbackForm.advice" :placeholder="$t('site.pleaseEnter',[$t('site.questionDes')])" type="textarea"  maxlength="300" :rows="3" show-word-limit></el-input>
          </el-form-item>
          <el-form-item :label="$t('site.questionScreenshot')" prop="questionScreenshot">
            <Screenshot v-model="questionScreenshot" @before-screenshot="handleBeforeScreenshot" @succsess-screenshot="handleSuccesssScreenshot"></Screenshot>
          </el-form-item>
          <el-form-item>
            <div class="dialog-footer">
              <el-button @click="drawerVisible = false">{{ $t('site.cancelBtn') }}</el-button>
              <el-button type="primary" @click="handleSave">{{ $t('site.okBtn') }}</el-button>
            </div>
          </el-form-item>
        </el-form>
        <div class="feedback-history" v-if="titleTabIndex === 1">
          <el-tabs v-model="activeName">
            <el-tab-pane label="已回复" name="first">
              <div class="fh-box" v-infinite-scroll="loadReplayList" :infinite-scroll-disabled="replyDisabled">
                <template v-if="myFeedback.reply.length > 0">
                  <el-descriptions
                    :column="1"
                    :border="true"
                    v-for="(item, index) in myFeedback.reply"
                    :key="index"
                    :labelStyle="{'width': '80px'}"
                  >
                    <el-descriptions-item label="问题类型">{{ item.questionValue }}</el-descriptions-item>
                    <el-descriptions-item label="问题描述">{{ item.advice }}</el-descriptions-item>
                    <el-descriptions-item label="问题截图">
                      <img :src="item.attachment" style="max-width:100%" />
                    </el-descriptions-item>
                    <el-descriptions-item label="回复内容">{{ item.reply }}</el-descriptions-item>
                    <el-descriptions-item label="用户评分">
                      <el-rate v-model="item.grade" @change="(val) => handleChangeGrade(item, val)" :disabled="!!item.grade"></el-rate>
                    </el-descriptions-item>
                  </el-descriptions>
                  <p v-if="replyloading" class="loading">加载中...</p>
                  <p v-if="replyNoMore && myFeedback.reply.length > 10" class="no-more">没有更多了</p>
                </template>
                <template v-else>
                  <el-empty :image="emptyImage" description="暂无已回复反馈"></el-empty>
                </template>
              </div>
            </el-tab-pane>
            <el-tab-pane label="未回复" name="second">
              <div class="fh-box" v-infinite-scroll="loadNoReplayList" :infinite-scroll-disabled="noReplyDisabled">
                <template v-if="myFeedback.noReply.length > 0">
                  <el-descriptions
                    :column="1"
                    :border="true"
                    v-for="(item, index) in myFeedback.noReply"
                    :key="index"
                    :labelStyle="{'width': '80px'}"
                  >
                    <el-descriptions-item label="问题类型">{{ item.questionValue }}</el-descriptions-item>
                    <el-descriptions-item label="问题描述">{{ item.advice }}</el-descriptions-item>
                    <el-descriptions-item label="问题截图">
                      <img :src="item.attachment" style="max-width: 100%" />
                    </el-descriptions-item>
                  </el-descriptions>
                  <p v-if="noReplyloading" class="loading">{{$t('site.loading')}}</p>
                  <p v-if="noReplyNoMore && myFeedback.noReply.length > 10" class="no-more">{{$t('site.noMore')}}</p>
                </template>
                <template v-else>
                  <el-empty :image="emptyImage" :description="$t('site.noData')"></el-empty>
                </template>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { getFeedbackTypeService, saveFeedbackService, uploadFeedbackFile, myFeedbackList, feedbackListGrade } from '@/services/feedback'
import { ssoLogin } from '@/services/login'
import Screenshot from '@/components/Screenshot.vue'
import { getPageInfo, guildSsoLogin } from '@/utils/common'
export default {
  components: {
    Screenshot
  },
  props: { },
  computed: {
    replyDisabled () {
      return this.replyloading || this.replyNoMore
    },
    noReplyDisabled () {
      return this.noReplyloading || this.noReplyNoMore
    }
  },
  data () {
    return {
      isAutoScreenshot: false, // 是否正在自动截图
      emptyImage: require('@/assets/images/empty.png'),
      drawerVisible: false,
      questionScreenshot: null,
      questionTypeOptions: [],
      titleMap: [
        {
          label: this.$t('site.feedback'),
          class: 'fb'
        },
        {
          label: this.$t('site.myFeedback'),
          class: 'my'
        }
      ],
      titleTabIndex: 0,
      feedbackForm: this.getInitFeedbackForm(),
      feedbackRules: {
        questionKey: [
          { required: true, message: this.$t('site.pleaseSelect', [this.$t('site.questionKey')]), trigger: 'blur' }
        ],
        advice: [
          { required: true, message: this.$t('site.pleaseEnter', [this.$t('site.questionDes')]), trigger: 'blur' }
        ]
      },
      activeName: 'first',
      myFeedback: {
        reply: [],
        noReply: []
      },
      paginationReply: {
        page: 1,
        pageSize: 10
      },
      paginationNoReply: {
        page: 1,
        pageSize: 10
      },
      replyloading: false,
      replyNoMore: false,
      noReplyloading: false,
      noReplyNoMore: false,
      isLogin: false
    }
  },
  watch: {
    // drawerVisible (isVisible) {
    //   if (isVisible) {
    //     // $checkSSO 判断用户是否登录
    //     this.$checkSSO(this.$route).then(() => {
    //       this.isLogin = true
    //       this.getMyfeedbackReplyList()
    //       this.getMyfeedbackNoReplyList()
    //       getFeedbackTypeService().then(questionTypeOptions => {
    //         this.questionTypeOptions = questionTypeOptions
    //       })
    //     }).catch(() => {
    //       this.isLogin = false
    //       this.guildSSOLogin()
    //       // ssoLogin(this.$route.fullPath)
    //     })
    //   }
    // }
  },

  methods: {
    handleChangeGrade (item, grade) {
      const data = {
        uniqueId: item.uniqueId,
        grade
      }
      feedbackListGrade(data).then(() => {
        this.$message.success('评分成功，感谢您对开放平台的支持。')
      })
    },
    handleOpenDrawer (isSdk) {
      // $checkSSO 判断用户是否登录
      this.$checkSSO(this.$route).then(async () => {
        this.isLogin = true
        await this.getMyfeedbackReplyList()
        await this.getMyfeedbackNoReplyList()
        getFeedbackTypeService().then(questionTypeOptions => {
          this.questionTypeOptions = questionTypeOptions
        })
        this.openDrawer(isSdk)
      }).catch(() => {
        this.isLogin = false
        !this.$store.state.isLoginNotify && guildSsoLogin.call(this)
      })
    },
    openDrawer (isSdk) {
      this.drawerVisible = true
      if (this.isLogin) {
        this.questionScreenshot = null
        this.feedbackForm = this.getInitFeedbackForm(isSdk) // 每次手动开启弹窗时都要初始化数据表单
        this.$nextTick(() => {
          this.$refs.feedbackForm.resetFields()
        })
      }
    },
    handleTitleChange (ev, className, index) {
      this.titleTabIndex = index
    },
    getInitFeedbackForm (isSdk) {
      return {
        ...getPageInfo(this.$route, isSdk),
        questionKey: '', // 问题类型
        advice: '', // 问题描述（意见建议）
        isAnonymous: false, // 是否匿名
        attachment: '' // 附件
      }
    },
    async handleSave () {
      let fileUrl = ''
      if (this.questionScreenshot) {
        fileUrl = await uploadFeedbackFile(this.questionScreenshot)
      }
      this.feedbackForm.attachment = fileUrl
      this.$refs.feedbackForm.validate((valid) => {
        if (valid) {
          saveFeedbackService(this.feedbackForm).then((data) => {
            this.$message.success(this.$t('site.feedbackSuccess'))
            this.drawerVisible = false
          })
        }
      })
    },
    // 开始截图前，需要先隐藏“反馈”弹窗
    handleBeforeScreenshot (screenshot) {
      // 先隐藏弹窗，再开启截图
      this.isAutoScreenshot = true
      this.$nextTick(() => {
        // 弹窗的隐藏有动画效果，需延时等待动画结束后才开始截屏
        setTimeout(() => {
          screenshot()
        }, 500)
      })
    },
    // 截图成功，需要显示“反馈”弹窗
    handleSuccesssScreenshot () {
      this.isAutoScreenshot = false
    },
    handleClosed () {
      this.$emit('closed')
    },
    getMyfeedbackReplyList () {
      const params = {
        isReply: 1,
        ...this.paginationReply
      }
      myFeedbackList(params).then(data => {
        this.myFeedback.reply = data.list
        if (data.total <= 10) {
          this.replyNoMore = true
        }
      })
    },
    getMyfeedbackNoReplyList () {
      const params = {
        isReply: 2,
        ...this.paginationNoReply
      }
      myFeedbackList(params).then(data => {
        this.myFeedback.noReply = data.list
        if (data.total <= 10) {
          this.noReplyNoMore = true
        }
      })
    },
    loadReplayList () { // 已回复无限加载
      this.replyloading = true
      setTimeout(() => {
        this.paginationReply.page += 1
        const params = {
          isReply: 1,
          ...this.paginationReply
        }
        myFeedbackList(params).then(data => {
          if (data.list.length < 1) {
            this.replyNoMore = true
            this.replyloading = false
          } else {
            this.myFeedback.reply = [...this.myFeedback.reply, ...data.list]
            this.replyloading = false
          }
        })
      }, 2000)
    },
    loadNoReplayList () { // 未回复无限加载
      this.noReplyloading = true
      setTimeout(() => {
        this.paginationNoReply.page += 1
        const params = {
          isReply: 2,
          ...this.paginationNoReply
        }
        myFeedbackList(params).then(data => {
          if (data.list.length < 1) {
            this.noReplyNoMore = true
            this.noReplyloading = false
          } else {
            this.myFeedback.noReply = [...this.myFeedback.noReply, ...data.list]
            this.noReplyloading = false
          }
        })
      }, 2000)
    }
  }
  // created () {
  //   this.getMyfeedbackReplyList()
  //   this.getMyfeedbackNoReplyList()
  // }
}
</script>

<style lang="stylus" scoped>
.dialog-footer
  text-align right
.question-type
  .el-radio
    line-height 32px
.drawer-body
  padding 0 20px
.drawer-title
  display flex
  font-size 14px
  .my
    font-weight normal
    margin-left 20px
  .cur
    color $hover-color
    border-bottom 1px solid $hover-color
  *
    cursor pointer
.feedback
  :deep(.el-drawer__header)
    margin-bottom 15px
.fh-box
  height calc(100vh - 122px - 60px)
  overflow-y auto
  .el-descriptions
    margin-bottom 20px
.loading,.no-more
  font-size 14px
  color $minor-text
  margin-top 0
</style>
