<template>
  <div class="right-floating-layer">
    <div
      v-if="!mobile"
      class="btn"
      @click="handleLike"
    >
      <svg-icon :icon-class="likeIcon"></svg-icon>
    </div>
    <el-tooltip
      :content="$t('site.feedback')"
      placement="left"
      v-if="!mobile"
    >
      <div
        class="btn"
        @click="handleOpenDrawer('drawer')"
      >
        <i class="el-icon-edit"></i>
      </div>
    </el-tooltip>
    <!-- 评论 -->
    <el-tooltip
      :content="$t('site.myComment')"
      placement="left"
    >
      <div
        class="btn"
        v-if="commentShow"
        @click="handleOpenDrawer('comment')"
      >
        <i class="el-icon-s-comment"></i>
      </div>
    </el-tooltip>
    <!-- 咨询 -->
    <div
      class="btn"
      v-if="isHideConsult"
      @click="handleOpenConsult"
    >
      <i class="el-icon-phone"></i>
    </div>
    <FeedbackDrawer
      ref="drawer"
      v-if="resetDrawer"
      @closed="handleClosed"
    ></FeedbackDrawer>
    <CommentDrawer
      ref="comment"
      v-if="resetDrawer"
      :resourceUniqueId="resourceUniqueId"
      @closed="handleClosed"
    />
  </div>
</template>

<script>
import FeedbackDrawer from '@/components/FeedbackDrawer.vue'
import CommentDrawer from '@/components/CommentDrawer.vue'
import { pageLikeService } from '@/services/feedback'
// import { ssoLogin } from '@/services/login'
import { PAGE_TYPE_DOC, PAGE_TYPE_PAGE } from '@/utils/constants'
import { getPageInfo, guildSsoLogin, isMobile } from '@/utils/common'
export default {
  components: {
    FeedbackDrawer,
    CommentDrawer
  },
  data () {
    return {
      isLikeActived: false,
      resourceUniqueId: '',
      pageInfo: {},
      resetDrawer: false,
      commentShow: false,
      mobile: isMobile()
    }
  },
  computed: {
    isHideConsult () {
      // console.log('--------this.$router', this.$router)
      return !this.$route.meta?.isHideConsult
    },
    likeIcon () {
      return this.isLikeActived ? 'like_fill' : 'like'
    }
  },
  watch: {
    $route (to, from) {
      if (this.$route.meta.pageType === PAGE_TYPE_PAGE || this.$route.meta.pageType === PAGE_TYPE_DOC) {
        this.commentShow = true
        this.resourceUniqueId = this.$route.params[this.$route.meta.idKey]
      } else {
        this.commentShow = false
        this.resourceUniqueId = ''
      }
      this.pageInfo = getPageInfo(this.$route)
      if (to.path !== from.path) {
        this.isLikeActived = false
      }
    }
  },
  methods: {
    handleLike () {
      this.pageInfo = { ...getPageInfo(this.$route) }
      // $checkSSO 判断用户是否登录
      this.$checkSSO(this.$route).then(() => {
        const isLike = !this.isLikeActived
        pageLikeService({
          ...this.pageInfo,
          status: isLike ? 1 : 2
        }).then(() => {
          this.isLikeActived = isLike
        })
      }).catch(() => {
        // ssoLogin(this.$route.fullPath)
        !this.$store.state.isLoginNotify && guildSsoLogin.call(this)
      })
    },
    handleOpenDrawer (type = 'drawer') {
      this.resetDrawer = true
      this.$nextTick(() => {
        this.$refs[type].handleOpenDrawer()
      })
    },
    handleClosed () {
      this.resetDrawer = false
    },
    handleOpenConsult () {
      window.open(this.$router.resolve({ name: 'consultFormPage' }).href, '_blank')
    }
  }
}
</script>

<style lang="stylus" scoped>
.right-floating-layer
  z-index $feedBackIndex
  position fixed
  bottom 90px
  right 20px
  .btn
    color $primary-color
    background-color #FFF
    width 40px
    height 40px
    margin-bottom 10px
    border-radius 50%
    display flex
    align-items center
    justify-content center
    font-size 20px
    box-shadow 0 0 6px rgba(0 0 0 12%)
    cursor pointer
    &:hover
      background-color $primary-hover-color
</style>
