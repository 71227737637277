<template>
  <div style="background: #cccccc2b;">
    <div v-for="(item, index) in subMenu" :key="index">
      <el-menu-item :index="item.uniqueId" v-if="(item.children||[]).length < 1">
        <a v-if="item.navUrl" :href="item.navUrl" :target="item.target === 1 ? '_blank' : ''">
          {{item.navTitle}}
        </a>
        <span v-else>{{item.navTitle}}</span>
      </el-menu-item>
      <el-submenu :index="item.uniqueId" v-else>
        <template slot="title">
          <a v-if="item.navUrl" :href="item.navUrl" :target="item.target === 1 ? '_blank' : ''">
            {{item.navTitle}}
          </a>
          <span v-else>{{item.navTitle}}</span>
        </template>
        <SmallMenuItem :sub-menu="item.children"></SmallMenuItem>
      </el-submenu>
    </div>
  </div>
</template>

<script>
import SmallMenuItem from './SmallMenuItem.vue'
export default {
  name: 'SmallMenuItem',
  components: {
    SmallMenuItem
  },
  props: ['subMenu']
}
</script>

<style lang="stylus" scoped>
/deep/.el-submenu__title, .el-menu-item
  a
    display block
    margin-right 60px
    color $side-menu-opened
    text-decoration none
</style>
