import Vue from 'vue'
import App from './App.vue'
import './assets/global.styl'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './plugin.ts'
import i18n from './i18n'
import './theme/index.css'
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
