/*
 * @Date: 2023-08-17 11:19:25
 * @LastEditors: 'lidianbin' 'lidianbin@coocaa.com'
 * @LastEditTime: 2023-08-17 11:31:16
 * @FilePath: \cms_main_site\src\services\login.js
 */
import fetch from './fetch'

function ssoLogin (backUrl) {
  const origin = window.location.origin
  backUrl = backUrl ? `${origin}${backUrl}` : window.location.href
  backUrl = encodeURIComponent(backUrl)
  // 通过 SSO 验证是否登录，若没登录，会跳转sso登录界面，登录后后端服务会重新到 redirectUrl
  window.location.href = `${origin}/violet-api/auth/user/checkLogin?state=${backUrl}`
  // window.location.href = `http://172.20.151.12:9080/admin/user/checkLogin?state=${backUrl}`
}

function ssoLogout ({ backUrl = '/', token } = {}) {
  const origin = window.location.origin
  backUrl = backUrl ? `${origin}${backUrl}` : window.location.href
  window.location.href = `${origin}/violet-api/auth/user/logout?backUrl=${backUrl}&token=${token}`
  // window.location.href = `http://172.20.151.12:9080/admin/user/logout?backUrl=${backUrl}`
}
function getUserDetail () {
  return fetch({
    method: 'get',
    url: 'auth/user/detail',
    hideError: true
  })
}
function checkUat (params) {
  return fetch({
    method: 'get',
    url: 'auth/user/checkUat',
    params,
    hideError: true
  })
}
export {
  ssoLogin,
  ssoLogout,
  getUserDetail,
  checkUat
}
